import CookieConsent from 'react-cookie-consent';
import { Text } from 'components/globals/TypographyElements';
import SVGIcon from '../SVGIcon';
import { StyledCookieBanner } from './style';

const CookieConsentBanner = () => {
  return (
    <StyledCookieBanner>
      <CookieConsent
        contentClasses="cookie-banner__content"
        buttonWrapperClasses="cookie-banner__button-wrapper"
        buttonClasses="cookie-banner__button"
        containerClasses="cookie-banner"
        buttonText="I understand"
      >
        <SVGIcon name="cookie" width={51} />
        <Text bold>This website uses cookies to enhance the user experience</Text>
      </CookieConsent>
    </StyledCookieBanner>
  );
};

export default CookieConsentBanner;
