import API from 'utils/API';

const getPackageSchedules = async () => {
  const { data } = await API.get(`/package-schedules`);

  const modifiedDeliveryDays = data
    // temporary show only Wednesday and Friday delivery days
    .filter(({ id }) => id === 1 || id === 4)
    .map(({ id, isDisabled }) => {
      let text;
      let value;
      if (id === 1) {
        text = 'Wednesday';
        value = 1;
      }

      if (id === 4) {
        text = 'Friday';
        value = 4;
      }
      return { text, value, isDisabled };
    });

  return modifiedDeliveryDays;
};

export default {
  getPackageSchedules,
};
