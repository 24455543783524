import React, { createContext, useMemo, useCallback, useContext, useReducer } from 'react';
import { useQuery } from 'react-query';
import { isAfter, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import subscriptionService from 'services/subscription.service';
import packageSchedulesService from 'services/packageSchedules.service';
import dates from 'utils/dates';
import planService from 'services/plan.service';
import { destroyCookie } from 'nookies';

export const CheckoutContext = createContext();

const initialData = {
  firstName: '',
  lastName: '',
  line1: '',
  line2: '',
  city: '',
  postcode: '',
  phone: '',
  email: '',
  survey: '',
};

export const initialState = {
  isLoading: false,
  isHiddenAddress: true,
  sameBillingAddress: true,
  currentStep: 'meals', // 'meals' | 'shipping' | 'payment'
  planInformation: {},
  discountPrice: null,
  discountCoupon: {},
  discountCouponError: null,
  deliveryDay: {},
  meals: [],
  extraPrice: 0,
  shippingData: { ...initialData },
  paymentData: { ...initialData },
  plans: [],
  currentPlan: {},
};

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DELIVERY_DAY':
      return {
        ...state,
        deliveryDay: action.payload,
      };

    case 'APPLY_DISCOUNT_COUPON_START':
      return {
        ...state,
        isLoading: true,
      };
    case 'APPLY_DISCOUNT_COUPON_SUCCESS':
      return {
        ...state,
        isLoading: false,
        discountCouponError: null,
        ...action.payload,
      };
    case 'APPLY_DISCOUNT_COUPON_FAILURE':
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case 'RESET_DISCOUNT_COUPON':
      return {
        ...state,
        discountCoupon: initialState.discountCoupon,
        discountPrice: initialState.discountPrice,
        discountCouponError: initialState.discountCouponError,
      };

    case 'CALCULATE_DISCOUNT_PRICE':
      return {
        ...state,
        discountPrice: action.payload,
      };

    case 'SET_PAYMENT_DATA':
      return {
        ...state,
        paymentData: action.payload,
      };
    case 'SET_SHIPPING_DATA':
      return {
        ...state,
        shippingData: action.payload,
      };
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'SET_PLAN_INFORMATION':
      return {
        ...state,
        planInformation: action.payload,
      };

    case 'SWITCH_SAME_BILLING_ADDRESS':
      return {
        ...state,
        sameBillingAddress: !state.sameBillingAddress,
      };
    case 'SWITCH_IS_HIDDEN_ADDRESS':
      return {
        ...state,
        isHiddenAddress: action.payload,
      };
    // TODO: ADD CURRENT PLAN
    case 'ADD_MEAL': {
      const currentPlan = state.plans.find((plan) => plan.numberOfMeals === state.meals.length + 1) || {};

      return {
        ...state,
        currentPlan,
        meals: [...state.meals, action.payload.id],
        extraPrice: state.extraPrice + action.payload.extraPrice,
      };
    }
    case 'REMOVE_MEAL': {
      const mealIndex = state.meals.indexOf(action.payload.id);
      const updatedMeals = state.meals.filter((_, index) => index !== mealIndex);
      const currentPlan = state.plans.find((plan) => plan.numberOfMeals === updatedMeals.length) || {};

      return {
        ...state,
        currentPlan,
        meals: updatedMeals,
        extraPrice: state.extraPrice - action.payload.extraPrice,
      };
    }
    case 'SET_PLANS':
      return {
        ...state,
        plans: action.payload,
      };

    case 'SET_PLAN':
      return {
        ...state,
        currentPlan: action.payload,
      };

    case 'RESET':
      const ignoreFields = action?.payload?.reduce((acc, cur) => ({ ...acc, [cur]: state[cur] }), {});

      return {
        ...initialState,
        deliveryDay: state.deliveryDay,
        plans: state.plans,
        ...ignoreFields,
      };
    default:
      return state;
  }
};

const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  // queries
  const deliveryDays = useQuery(['deliveryDays'], packageSchedulesService.getPackageSchedules, {
    // check enabled logic
    retry: true,
    onSuccess: (data) => {
      const firstActiveDeliveryDay = data.find(({ isDisabled }) => !isDisabled) || {};

      dispatch({ type: 'SET_DELIVERY_DAY', payload: firstActiveDeliveryDay });
    },
  });

  useQuery(['allPlans'], planService.getAllPlans, {
    // check enabled logic
    retry: true,
    onSuccess: (data) => {
      dispatch({ type: 'SET_PLANS', payload: data });
    },
  });

  const deliveryDate = useMemo(() => {
    if (state.deliveryDay.value === 1 && isAfter(dates.UTCTime, dates.fridayFourPM)) {
      return dates.wednesdayAfterWeek;
    }

    if (state.deliveryDay.value === 1) {
      return dates.nextWednesday;
    }

    if (state.deliveryDay.value === 4 && isBefore(dates.UTCTime, dates.monday)) {
      return dates.friday;
    }

    if (state.deliveryDay.value === 4) {
      return dates.nextFriday;
    }
  }, [state.deliveryDay]);

  const calculateAmount = (totalPrice, discount) => {
    if (discount.percentOff) {
      return totalPrice * (1 - discount.percentOff / 100) || 100;
    }

    return totalPrice - discount.amountOff;
  };

  const applyDiscountCoupon = useCallback(
    async (coupon, planPrice = state.currentPlan.totalPrice) => {
      const totalPrice = planPrice + state.extraPrice;

      if (coupon === state.discountCoupon.id) {
        const totalWithDiscount = calculateAmount(totalPrice, state.discountCoupon);

        dispatch({
          type: 'CALCULATE_DISCOUNT_PRICE',
          payload: totalWithDiscount,
        });
        return;
      }

      try {
        dispatch({ type: 'APPLY_DISCOUNT_COUPON_START' });

        const { data } = await subscriptionService.checkCoupon(coupon.trim());
        const totalWithDiscount = calculateAmount(totalPrice, data.result);

        dispatch({
          type: 'APPLY_DISCOUNT_COUPON_SUCCESS',
          payload: { discountCoupon: data.result },
        });

        dispatch({
          type: 'CALCULATE_DISCOUNT_PRICE',
          payload: totalWithDiscount,
        });
      } catch (err) {
        destroyCookie(null, 'coupon', {
          path: '/',
        });

        dispatch({
          type: 'APPLY_DISCOUNT_COUPON_FAILURE',
          payload: { discountCouponError: err.response?.data.message, discountPrice: totalPrice },
        });
      }
    },
    [state.currentPlan.totalPrice, state.discountCoupon, state.extraPrice]
  );

  return (
    <CheckoutContext.Provider
      value={{
        state,
        deliveryDays,
        deliveryDate,
        dispatch,
        applyDiscountCoupon,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

CheckoutProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CheckoutProvider;

export const useCheckout = () => useContext(CheckoutContext);
