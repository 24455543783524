import API from 'utils/API';

const getPlan = async (planId) => {
  const data = await API.get(`/plans/${planId}`);

  return data;
};

const getAllPlans = async () => {
  const { data } = await API.get(`/plans`);

  return data;
};

export default {
  getPlan,
  getAllPlans,
};
