import Router from 'next/router';
import withFBQ from 'next-fbq';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import NextNProgress from 'nextjs-progressbar';
import Intercom from 'react-intercom';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { AnimatePresence } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
// components
import CookieConsentBanner from 'components/CookieConsentBanner';
import GlobalStyles from 'components/GlobalStyles';
// context
import CheckoutProvider from 'context/CheckoutContext';
import AuthProvider from 'context/AuthContext';
import PromoBarProvider from 'context/PromoBarContext';

// styles
import { theme } from 'styles/theme';
import 'react-phone-input-2/lib/style.css';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/swiper-bundle.min.css';
// Import Swiper styles

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount) {
        if (failureCount < 2) return true;
        return false;
      },
    },
  },
});

// handle Stripe error;
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};

const MyApp = ({ Component, pageProps, router, err }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <CookieConsentBanner />
        <Intercom appID={process.env.INTERCOM_APP_API} />
        {/* Animations  */}
        <AnimatePresence exitBeforeEnter>
          {/* React query config */}
          <QueryClientProvider client={queryClient}>
            {/* Authentication for customer */}
            <AuthProvider>
              {/* Checkout logic */}
              <CheckoutProvider>
                {/* Promo bar logic */}

                <PromoBarProvider>
                  {/* Stripe options */}
                  <Elements stripe={getStripe()}>
                    <Component {...pageProps} key={router.route} err={err} />
                    <NextNProgress height={6} color="#FF821E" options={{ showSpinner: false }} />

                    <ToastContainer autoClose={5000} />
                    <ReactQueryDevtools initialIsOpen />
                  </Elements>
                </PromoBarProvider>
              </CheckoutProvider>
            </AuthProvider>
          </QueryClientProvider>
        </AnimatePresence>
      </ThemeProvider>
    </>
  );
};

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  pageProps: PropTypes.object,
  router: PropTypes.object.isRequired,
  err: PropTypes.object,
};

export default withFBQ(process.env.FACEBOOK_PIXEL_ID, Router)(MyApp);
