import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { swiperStyles, toastStyles } from 'styles/helpers';
import paralucentMedium from 'fonts/paralucent-W00-medium.ttf';
import paralucentLight from 'fonts/paralucent-W00-light.ttf';

const GlobalStyles = createGlobalStyle`
  ${normalize()};
  ${toastStyles}
  ${swiperStyles}

  @font-face {
    font-family: 'paralucent-medium';
    src: url(${paralucentMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'paralucent-light';
    src: url(${paralucentLight}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin:0;
    padding:0;
    -webkit-overflow-scrolling: touch;
  }

  h1 {
    margin:0;
  }
  
  html {
    height: 100%;
    width: 100%;
    position: relative;
    /* smooth the font on the level of the pixel */
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'paralucent-medium', sans-serif;
    height: 100%;
    width:100%;
    align-items: center;
    overflow-x: hidden; 

    /* smooth effect for mobile devices */
    /* -webkit-overflow-scrolling: touch; */
    /* disabled zoom for mobile devices */
    touch-action: pan-x pan-y;
  }

  #__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden; */
  }



  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

  button {
    font-family: 'paralucent-medium', sans-serif;
    cursor:pointer;
  }

   /* 
   Safari styles  
   */

/* temporary fix for common questions sections */
  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      .common-questions {
        background-attachment: initial;
      }
    }
  }
  `;

export default GlobalStyles;
