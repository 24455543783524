import styled from 'styled-components';
import { device } from 'styles/device';

/* 
 !important is required to reassign inline styles from react-cookie-consent library 
 */

export const StyledCookieBanner = styled.div`
  .cookie-banner {
    align-items: center !important;
    background: #fff0d5 !important;
    border-radius: 8px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    color: black;
    padding: 0 80px 24px 16px;

    &__content {
      align-items: center;
      display: flex !important;
      flex: 0 1 300px !important;
      margin: 0px !important;
      padding: 8px 0 16px 0;

      svg {
        margin-right: 16px;
        min-width: 51px;
      }
    }

    &__button-wrapper {
      flex: 1 1 320px;
    }

    &__button {
      background: #ffb432 !important;
      border-radius: 8px !important;
      margin: 0 !important;
      padding: 8px 28px !important;
    }

    @media ${device.tablet} {
      padding: 0 80px 8px 16px;

      &__content {
        padding: 8px 0 8px 0;
        flex: 0 1 640px !important;
      }
    }
  }
`;
